<template>
	<div class="job-management">
		<div class="el-management">
			<div class="management-title">基本信息</div>
			<div class="management-text">
				职位发布成功后，招聘类型、职位名称、职位类型、工作地点，将无法修改
			</div>
			<div class="management-name">
				<span style="letter-spacing: 34px">公</span>司：<span class="text-main">{{
          entDetail.ent_name
        }}</span>
			</div>
			<div class="row-center management-input">
				<span>招聘类型：</span>
				<el-select v-model="query.type" placeholder="请选择招聘类型">
					<el-option v-for="item in position_types" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="row-center management-input">
				<span>职位名称：</span>
				<el-input placeholder="输入职位名称" v-model="query.position_name"></el-input>
			</div>
			<div class="row management-input">
				<span>职位描述：</span>
				<el-input placeholder="请勿填写QQ、微信、电话等联系方式及特殊符号、性别歧视词、违法劳动法 及相关法律内容，否则可能会导致您的账号被封禁。" type="textarea"
					v-model="query.description"></el-input>
			</div>
			<div class="row-center management-input management-input-two">
				<span>职位类型：</span>
				<div class="input-disaple" @click="zwCategory">{{ zwText }}</div>
			</div>
			<div class="management-require space-between">
				<div>职位要求</div>
				<div class="flex-center el-require-img">
					<span class="text-white">精准人才推荐，马上了解</span>
					<img src="../../../assets/glxt/11.png" alt="" />
				</div>
			</div>
			<div class="management-text">
				我们将通过以下条件，为您精准推荐合适的人才，请尽量详细填写
			</div>
			<div class="experience-education row-center">
				<span>经验和学历：</span>
				<div class="el-experience">
					<el-select v-model="query.experience_id" @change="worksChange" placeholder="请选择经验">
						<el-option v-for="item in works" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="el-experience education">
					<el-select v-model="query.education_id" @change="educationChange" placeholder="请选择学历">
						<el-option v-for="item in education_tyoe" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="experience-education row-center">
				<span>薪资范围：</span>
				<div class="el-experience">
					<el-select v-model="salary_min" @change="minChange" placeholder="选择薪资范围">
						<el-option v-for="item in priceOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="experience-text">至</div>
				<div class="el-experience">
					<el-select v-model="salary_max" @change="maxChange" :disabled="disabled" placeholder="选择薪资范围">
						<el-option v-for="item in priceOptionsOne" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="row-center management-input">
				<span>工作地点：</span>
				<div class="input-disaple text-ellipsis ellip" @click="openAddress">
					{{ addressInput }}
				</div>
			</div>
			<div class="management-fb row-end">
				<div class="bg-blue text-white flex-center pointer" @click="releaseClick">
					发布
				</div>
			</div>
		</div>
		<!-- 职位类型选择弹窗 -->
		<scolle-view ref="scoll" @rightClick="rightClick"></scolle-view>
		<!-- 地址选择 -->
		<address-update ref="chooseAddress" @addRessClick="addRessClick" @checkClick="checkClick" @useClick="useClick">
		</address-update>
		<!-- 添加地址 -->
		<companyAddress ref="addAddress" @hide="hideOne" :title="title" :itemAddress="itemAddress"></companyAddress>
		<!-- 发布成功弹窗 -->
		<mask-model title="发布成功" :btnCancel="false" sureContent="去认证" ref="mask" width="482px" :interview="false"
			@hide="hide" @submitClick="submitClick">
			<div class="mask-model">
				系统检测到您还未进行企业认证，认证后职位将展示给更多的人才
			</div>
		</mask-model>
	</div>
</template>

<script>
	import scolleView from "./scolle.vue";
	import addressUpdate from "./address.vue";
	import companyAddress from "@/components/companyAddress.vue";
	import maskModel from "@/components/mask.vue";
	import {
		mapState
	} from "vuex";
	import toolApi from "../../../api/tool";
	import api from "../../../api/position";
	import addressApi from "../../../api/address";
	export default {
		components: {
			scolleView,
			addressUpdate,
			companyAddress,
			maskModel,
		},
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["entDetail"]),
		},
		data() {
			return {
				priceOptions: [],
				priceOptionsOne: [],
				zwText: "请选择职位类型",
				// 判断企业是否认证
				certificationShow: true,
				// 招聘类型数据
				position_types: [],
				// 经验
				works: [],
				// 学历
				education_tyoe: [],
				query: {
					id: 0,
					type: "", //招聘类型
					position_name: "", //职位名称
					description: "", //职位描述
					experience: "", //经验要求
					education: "", //学历要求，
					salary_min: "", //最低薪资
					salary_max: "", //最高薪资
					experience_id: "", //经验id
					education_id: "", //学历id
					province: "",
					city_id: "",
					city: "",
					address: "",
					province_id: "", //省份id
					one_position_id: "", //一级职位id
					two_position_id: "", //二级职位id,
					two_position: "", //二级职位名称
				},
				salary_min: "", //最低工资
				salary_max: "", //最高工资
				addressInput: "", //工作地址
				itemAddress: null, //查看地址详情所需要的数据
				title: "添加地址",
				releaseShow: true,
				disabled: true,
			};
		},

		created() {
			this.toolDeatil();
			if (!this.$route.query.id) {
				this.assignClick(this.entDetail);
			} else {
				this.posiDetail(this.$route.query.id);
			}

			let priceListOne = [];
			for (let index = 0; index < 29; index++) {
				priceListOne.push({
					value: index + 1,
					label: index + 1 + "k",
				});
			}
			let priceListTwo = [];
			for (let index = 30; index <= 100; index++) {
				if (index % 5 == 0) {
					priceListTwo.push({
						value: index,
						label: index + "k",
					});
				}
			}

			this.priceOptions = [...priceListOne, ...priceListTwo];
			this.priceOptionsOne = [...priceListOne, ...priceListTwo];
		},
		watch: {
			entDetail(val) {
				this.assignClick(val);
			},
		},
		mounted() {},

		methods: {
			// 获取职位详情
			posiDetail(id) {
				const loading = this.$loading({
					lock: true,
					text: "加载中",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				api.posiDetail({
					id
				}).then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.query.id = data.id;
						this.query.type = data.type;
						this.query.position_name = data.position_name;
						this.query.description = data.description;
						this.query.experience = data.experience;
						this.query.education = data.education;
						this.query.salary_min = data.salary_min;
						this.query.salary_max = data.salary_max;
						this.query.experience_id = data.experience_id;
						this.query.education_id = data.education_id;
						this.query.province = data.province;
						this.query.city_id = data.city_id;
						this.query.city = data.city;
						this.query.address = data.address;
						this.query.province_id = data.province_id;
						this.zwText = data.two_position;
						this.query.two_position = data.two_position;
						this.query.two_position_id = data.two_position_id;
						this.query.one_position_id = data.one_position_id;
						this.addressInput = data.province + data.city + data.address;
						if (data.salary_min) {
							this.disabled = false;
							let salary_min = this.priceOptions.filter((item) => {
								return item.label == data.salary_min;
							});
							this.salary_min = salary_min[0].value;
						}
						if (data.salary_max) {
							let salary_max = this.priceOptions.filter((item) => {
								return item.label == data.salary_max;
							});
							this.salary_max = salary_max[0].value;

							let priceOptionsOne = this.priceOptions.map((item) => {
								if (item.value >= salary_max[0].value) {
									return item;
								}
							});
							let priceListTwo = priceOptionsOne.filter((item) => {
								return item;
							});
							this.priceOptionsOne = priceListTwo;
						}
					}
					loading.close();
				});
			},
			useClick(val) {
				this.assignClick(val);
			},
			// 赋值
			assignClick(val) {
				this.query.province_id = val.province_id;
				this.query.city_id = val.city_id;
				this.query.province = val.province;
				this.query.city = val.city;
				this.query.address = val.address;
				this.addressInput = val.province + val.city + val.address;
				// console.log(val);
			},
			// 取出经验选中的label值
			worksChange(val) {
				// console.log(val);
				let obj = this.works.find((item) => {
					return item.value == val;
				});
				this.query.experience = obj.label;
			},
			// 取出学历选中的lable
			educationChange(val) {
				let obj = this.education_tyoe.find((item) => {
					return item.value == val;
				});
				this.query.education = obj.label;
			},
			// 取出选中最低工资的label
			minChange(e) {
				this.disabled = false;
				let salary_min = this.priceOptions.filter((item) => {
					return item.value == e;
				});

				this.query.salary_min = salary_min[0].label;
				if (e == 95 || e == 100) {
					this.salary_max = 100;
					this.query.salary_max = "100k";
					this.priceOptionsOne = [{
						value: 100,
						label: "100k",
					}, ];
				} else {
					if (e < 30) {
						this.salary_max = e + 1;
					} else {
						this.salary_max = e + 5;
					}

					let priceOptionsOne = this.priceOptions.map((item) => {
						if (item.value > e) {
							return item;
						}
					});
					let priceListTwo = priceOptionsOne.filter((item) => {
						return item;
					});
					this.priceOptionsOne = priceListTwo;

					let salary_max = priceListTwo.filter((item) => {
						return item.value == this.salary_max;
					});
					this.query.salary_max = salary_max[0].label;
				}
			},
			// 取出选中最高工资的lable
			maxChange(e) {
				let salary_max = this.priceOptionsOne.filter((item) => {
					return item.value == e;
				});
				this.query.salary_max = salary_max[0].label;
			},
			// 获取招聘类型
			toolDeatil() {
				toolApi.getUserConfig().then((res) => {
					if (res.code == 200) {
						this.position_types = res.data.position_types.options;
						this.works = res.data.works.options;
						this.education_tyoe = res.data.education_tyoe.options;
					}
				});
			},
			// 弹出职位类型选择弹窗
			zwCategory() {
				this.$refs.scoll.show();
			},
			// 职位类型选中事件
			rightClick(two_position_id, two_position, one_position_id) {
				this.zwText = two_position;
				this.query.one_position_id = one_position_id;
				this.query.two_position = two_position;
				this.query.two_position_id = two_position_id;
			},
			// 打开工作地点
			openAddress() {
				this.$refs.chooseAddress.show();
			},
			// 弹出添加新地址弹窗
			addRessClick() {
				this.title = "添加地址";
				this.$refs.chooseAddress.hide();
				this.$refs.addAddress.show();
			},
			// 弹出查看地址
			checkClick(item) {
				this.title = "修改地址";
				this.itemAddress = item;
				this.$refs.chooseAddress.hide();
				this.$refs.addAddress.show();
			},
			// 关闭添加新地址弹窗，弹出选择地址弹窗
			hideOne() {
				this.$refs.chooseAddress.show();
			},
			// 提交接口
			posiSet() {
				const loading = this.$loading({
					lock: true,
					text: "发布中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				api
					.posiSet(this.query)
					.then((res) => {
						if (res.code == 200) {
							this.$util.msg(res.msg);
							// 判断是否已经进行企业认证了，没有的话弹出认证弹窗
							if (this.entDetail.is_auth !== 1) {
								this.$refs.mask.show();
							} else {
								setTimeout(() => {
									this.releaseShow = true;
									this.$router.push({
										path: "/layout/positionRelease"
									});
								}, 1000);
							}
						} else {
							this.releaseShow = true;
						}
						loading.close();
					})
					.catch((err) => {
						console.log(err);
					});
			},
			// 发布
			releaseClick() {

				if (!this.releaseShow) return;
				this.releaseShow = false;
				this.posiSet();
			},
			hide() {
				this.$router.push({
					path: "/layout/positionRelease"
				});
			},
			// 企业没有认证的去认证
			submitClick() {
				this.$router.push({
					path: "/layout/account"
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.job-management {
		width: 100%;
		padding: 1.25rem 9.125rem 1.375rem 9.1875rem;

		.el-management {
			width: 100%;
			padding: 2.125rem 25.25rem 2.375rem 25.3125rem;
			background: #fff;
			box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);

			.management-title {
				font-size: 1.125rem;
				color: #222222;
			}

			.management-text {
				color: #757575;
				font-size: 0.8125rem;
				margin-top: 0.625rem;
				margin-bottom: 2.4375rem;
			}

			.management-name {
				color: #757575;
				font-size: 0.875rem;
				margin-bottom: 1.875rem;
			}

			.management-input {
				margin-bottom: 1.25rem;

				span {
					color: #757575;
					font-size: 0.875rem;
				}

				.input-disaple {
					flex: 1;
					height: 2.5rem;
					border: 1px solid #e3e7ed;
					border-radius: 3px;
					padding-left: 0.6875rem;
					line-height: 2.375rem;
					color: #d1d4de;
					font-size: 0.875rem;
					cursor: pointer;
				}

				/deep/.el-select {
					flex: 1;
				}

				/deep/.el-input {
					flex: 1;
				}

				/deep/.el-textarea {
					flex: 1;
					height: 9.1875rem;
				}

				/deep/.el-textarea__inner {
					height: 9.1875rem;
				}
			}

			.management-input-two {
				margin-bottom: 2.5rem;
			}

			.management-require {
				width: 100%;
				padding-right: 0.6875rem;
				color: #222222;
				font-size: 1.125rem;
				margin-bottom: 0.3125rem;

				.el-require-img {
					width: 181px;
					height: 30px;
					background: linear-gradient(-2deg, #126bf9 0%, #85b4ff 100%);
					border-radius: 0.3125rem;
					font-size: 0.8125rem;

					img {
						width: 0.5625rem;
						height: 0.5rem;
						margin-left: 0.5rem;
					}
				}
			}

			.experience-education {
				color: #757575;
				font-size: 0.875rem;
				margin-bottom: 1.3125rem;

				.el-experience {
					width: 150px;
					height: 34px;
				}

				.education {
					margin-left: 1.1875rem;
				}

				.experience-text {
					margin: 0 0.625rem;
				}
			}

			.management-fb {
				div {
					width: 4.25rem;
					height: 2.125rem;
					border-radius: 1px;
					font-size: 13px;
				}
			}
		}

		.mask-model {
			color: #9fa3b0;
			font-size: 14px;
			padding-top: 1.0625rem;
			padding-bottom: 1.9375rem;
		}
	}
</style>
