<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="position-absolute address-view">
        <div class="row-end">
          <i class="el-icon-close pointer" @click="status = false"></i>
        </div>
        <div class="address-title text-main">请选择工作地址</div>
        <div class="address-text">
          请填写真实有效地址，若查实造假，账号将被冻结，可前往[设置-地址管理]中管理地址
        </div>
        <div class="row-center address-search">
          <div class="text-main">城市</div>
          <div class="select-city">
            <el-select
              v-model="query.province_id"
              @change="searchClick"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="address-input">
            <el-input
              placeholder="搜索工作地址"
              v-model="query.address"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchClick"
              ></el-button>
            </el-input>
          </div>
        </div>
        <div class="address-table">
          <div class="space-between table-text text-main">
            <span>地址</span>
            <span>操作</span>
          </div>
        </div>
        <div class="address-radio" @scroll="scrollEvent">
          <!-- 局部滚动组件 -->
          <div
            class="address-center space-between"
            v-for="(item, index) in addressList"
            :key="index"
          >
            <div class="center-left text-ellipsis ellip">
              <el-radio v-model="radio" :label="item.id"
                >{{ item.province }}{{ item.city }}{{ item.address }}</el-radio
              >
            </div>
            <div class="center-right pointer" @click="checkClick(item)">
              {{ item.operate }}
            </div>
          </div>
          <div class="last-text" v-if="loading">加载中...</div>
          <div class="last-text" v-if="noMore && addressList.length > 0">没有更多了</div>
          <div class="last-text" v-if="addressList.length == 0">暂无数据</div>
        </div>
        <div class="address-button row-end">
          <div class="text-blue pointer flex-center" @click="addRessClick">
            添加新地址
          </div>
          <div
            class="text-white pointer flex-center bg-blue"
            :class="[radio === '' ? '' : 'div-opcity']"
            @click="useClick"
          >
            使用该地址
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toolApi from "../../../api/tool";
import api from "../../../api/address";
export default {
  components: {},

  data() {
    return {
      status: false,
      options: [],
      addressList: [],
      radio: "",
      query: {
        address: "",
        province_id: "",
        pageSize: 10,
        page: 1,
      },
      addressShow: true,
      last_page: 1,
      loading: false,
    };
  },

  created() {
    this.provinceList();
  },

  mounted() {},
  computed: {
    noMore() {
      return this.query.page >= this.last_page;
    },
  },
  methods: {
    // 判断滚动条滚动到底部
    scrollEvent(e) {
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        if (this.query.page <= this.last_page) {
          this.loading = true;
          this.query.page += 1;
          this.addressShow = false;
          this.getAddress();
        }
      }
    },
    searchClick() {
      this.query.page = 1;
      this.addressShow = true;
      this.getAddress();
    },
    // 获取地址
    getAddress() {
      api.getAddress(this.query).then((res) => {
        if (res.code == 200) {
          this.last_page = res.data.last_page;
          res.data.data.forEach((item) => {
            item.operate = "查看";
          });
          if (this.addressShow) {
            this.addressList = res.data.data;
          } else {
            this.loading = false;
            this.addressList = this.addressList.concat(res.data.data);
          }
        }
      });
    },
    // 获取省份
    provinceList() {
      toolApi.getAddress().then((res) => {
        if (res.code == 200) {
          let data = res.data.children;
          data.forEach((element) => {
            element.children = "";
          });
          this.options = data;
        }
      });
    },
    // 显示
    show() {
      this.getAddress();
      this.status = true;
    },
    // 隐藏
    hide() {
      this.query.address = "";
      this.query.province_id = "";
      this.query.page = 1;
      this.query.pageSize = 10;
      this.addressShow = true;
      this.getAddress();
      this.status = false;
    },
    // 打开新添加地址
    addRessClick() {
      this.$emit("addRessClick");
    },
    // 查看新添加的地址
    checkClick(item) {
      this.$emit("checkClick", item);
    },
    // 使用该地址
    useClick() {
      if (this.radio == "") {
        this.$util.msg("请选择地址", "warning");
        return;
      }
      // console.log(this.radio);
      const obj = this.addressList.filter((item) => {
        return item.id == this.radio;
      });
      this.$emit("useClick", obj[0]);
      this.hide();
    },
  },
};
</script>
<style lang="less" scoped>
.address-view {
  width: 51.4375rem;
  min-height: 41.3125rem;
  background: #ffffff;
  border-radius: 0.375rem;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.4375rem 1.4375rem 1.4375rem 1.875rem;
  .address-title {
    font-size: 1.125rem;
  }
  .address-text {
    color: #9fa3b0;
    font-size: 0.875rem;
    margin-top: 0.625rem;
    margin-bottom: 1.5625rem;
  }
  .address-search {
    margin-bottom: 1.1875rem;
    height: 2.125rem;
    font-size: 0.875rem;
    .select-city {
      margin-left: 0.6875rem;
      width: 11.25rem;
      height: 100%;
      margin-right: 0.875rem;
    }
    .address-input {
      width: 18.75rem;
      height: 100%;
    }
  }
  .address-table {
    width: 100%;
    height: 2.8125rem;
    padding-right: 0.375rem;
    .table-text {
      width: 100%;
      height: 100%;
      padding: 0 3.125rem;
      font-size: 0.875rem;
      background: #fafafb;
    }
  }
  .address-radio {
    width: 100%;
    height: 25.4375rem;
    padding: 0 3.125rem 0 3.3125rem;
    overflow-y: auto;
    .address-center {
      padding: 1.375rem 0;
      .center-left {
        width: 80%;
        /deep/.el-radio {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
    .last-text {
      text-align: center;
    }
  }
  .address-radio::-webkit-scrollbar {
    display: none;
  }
  .address-button {
    font-size: 0.875rem;
    div {
      width: 6.5rem;
      height: 2.125rem;
    }
    div:first-child {
      border: 1px solid #126bf9;
      margin-right: 0.75rem;
    }
    div:last-child {
      opacity: 0.5;
    }
    .div-opcity {
      opacity: 1 !important;
    }
  }
}
</style>
