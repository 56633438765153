<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="position-absolute sclooe-view column">
        <div class="scolle-title space-between">
          <span class="text-main">请选择职类</span>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="scolle-center row one">
          <div class="center-left">
            <!-- 局部滚动组件 -->
            <div
              class="left-item text-main position-relative pointer"
              :class="{ active: index == activeIndex }"
              v-for="(item,index) in leftItem"
              :key="index"
              @click="activeClick(index,item.id)"
            >
             {{item.title}}
            </div>
          </div>
          <div class="center-right one">
            <div class="row right-center">
              <div
                class="right-item text-main pointer"
                v-for="(item, index) in leftItem[activeIndex].childs"
                :key="index"
                @click="rightClick(item.id,item.title)"
              >
                {{ item.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toolApi from "../../../api/tool"
export default {
  components: {},

  data() {
    return {
      status: false,
      activeIndex: 0,
      leftItem:[],
      one_position_id:"",//一级职位id
    };
  },

  created() {
    this.posiCategory()
  },

  mounted() {},

  methods: {
    // 获取职位类型
    posiCategory(){
      toolApi.positionCategory().then(res=>{
        if(res.code==200){
          this.leftItem=res.data.data
          this.one_position_id=res.data.data[0].id
        }
      })
    },
    // 显示
    show() {
      this.status = true;
    },
    // 隐藏
    hide() {
      this.status = false;
    },
    // 左侧点击事件
    activeClick(index,one_position_id) {
      this.activeIndex = index;
      this.one_position_id=one_position_id
    },
    // 右侧点击事件
    rightClick(two_position_id,two_position) {
      this.$emit("rightClick", two_position_id,two_position,this.one_position_id);
      this.hide();
    },
  },
};
</script>
<style lang='less' scoped>
.sclooe-view {
  width: 55.5625rem;
  height: 36rem;
  background: #ffffff;
  border-radius: 0.375rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  .scolle-title {
    width: 100%;
    height: 2.875rem;
    padding: 0 1.4375rem;
    span {
      font-size: 1rem;
    }
  }
  .scolle-center {
    width: 100%;
    height: 33.125rem;
    .center-left {
      width: 9.125rem;
      background: #f9fafb;
      height: 100%;
      padding-top: 0.625rem;
      overflow: auto;
      .left-item {
        width: 100%;
        height: 2.5rem;
        font-size: 0.875rem;
        padding-left: 1.4375rem;
        line-height: 2.5rem;
        &:before {
          //在分类前添加竖线
          position: absolute;
          width: 0;
          content: "";
          height: 0;
          border-right: 0.375rem solid #126bf9;
          border-radius: 0.875rem;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.3s;
        }
      }
      .active {
        background: #ffffff;
        &:before {
          width: 0;
          height: 2.5rem;
        }
      }
    }
    .center-left::-webkit-scrollbar {
      display: none;
    }
    .center-right {
      padding: 1.5rem 5.8125rem 0 1.6875rem;
      height: 100%;
      font-size: 0.875rem;
      overflow-y: auto;
      .right-center {
        div {
          width: 25%;
          margin-bottom: 1.625rem;
        }
      }
    }
    .center-right::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>